import React from 'react';
import LoginPage from '../../auth/pages/LoginPage';

function AuthLayout() {
  return (
    <div className='min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'>
      <LoginPage />
    </div>
  );
}

export default AuthLayout;
