import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export function Link({
  children,
  className = 'text-indigo-600 hover:text-indigo-900',
  ...otherProps
}) {
  return (
    <RouterLink className={className} {...otherProps}>
      {children}
    </RouterLink>
  );
}
