import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { Transition } from '@headlessui/react';
import { NavLink } from 'react-router-dom';

import exploradogsLogo from '@assets/img/exploradogs-logo.png';
import { currentUserVar } from '@graphql/reactive-variables';
import { useAuth } from '../providers/AuthProvider';
import { getThumbnailUrl } from '@utils/image.utils';

function NavbarItem({ children, to, exact = true, isMobileLayout = false }) {
  const desktopClasses =
    'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium';
  const mobileClasses =
    'text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium';
  return (
    <NavLink
      activeClassName={'bg-gray-900 text-white'}
      className={isMobileLayout ? mobileClasses : desktopClasses}
      to={to}
      exact={exact}
    >
      {children}
    </NavLink>
  );
}

function MainMenu({ routes, isMobileLayout = false }) {
  return routes.map((route) => {
    if (route.showInNavbar) {
      return (
        <NavbarItem
          key={route.path}
          to={route.path}
          isMobileLayout={isMobileLayout}
        >
          {route.name}
        </NavbarItem>
      );
    }

    return null;
  });
}

export function Navbar({ routes }) {
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const { logout } = useAuth();
  const toggleProfileDropdown = useCallback(
    () => setIsProfileDropdownOpen(!isProfileDropdownOpen),
    [setIsProfileDropdownOpen, isProfileDropdownOpen]
  );
  const userPictureUrl = getThumbnailUrl(currentUserVar().url);

  return (
    <nav className='bg-gray-800'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex items-center justify-between h-16'>
          <div className='flex items-center'>
            <div className='flex-shrink-0'>
              <img
                className='h-12 w-12 rounded-xl'
                src={exploradogsLogo}
                alt='Workflow'
              />
            </div>
            <div className='hidden md:block'>
              <div className='ml-10 flex items-baseline space-x-4'>
                <MainMenu routes={routes} />
              </div>
            </div>
          </div>
          <div className='hidden md:block'>
            <div className='ml-4 flex items-center md:ml-6'>
              {/* <button className='bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                  <span className='sr-only'>View notifications</span>
                  <svg
                    className='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokelnecap='round'
                      strokelnejoin='round'
                      strokeWidth='2'
                      d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
                    />
                  </svg>
                </button> */}

              <div className='ml-3 relative'>
                <div>
                  <button
                    className='max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
                    id='user-menu'
                    aria-haspopup='true'
                    onClick={toggleProfileDropdown}
                  >
                    <span className='sr-only'>Open user menu</span>
                    <img
                      className='h-8 w-8 rounded-full'
                      src={userPictureUrl}
                      alt=''
                    />
                  </button>
                </div>
                <Transition
                  show={isProfileDropdownOpen}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <div
                    className='z-20 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5'
                    role='menu'
                    aria-orientation='vertical'
                    aria-labelledby='user-menu'
                  >
                    <span
                      className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                      onClick={logout}
                    >
                      Sign out
                    </span>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
          <div className='-mr-2 flex md:hidden'>
            <button
              className='bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
              onClick={toggleProfileDropdown}
            >
              <span className='sr-only'>Open main menu</span>
              <svg
                className={cn('h-6 w-6', {
                  block: !isProfileDropdownOpen,
                  hidden: isProfileDropdownOpen,
                })}
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  strokelnecap='round'
                  strokelnejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                />
              </svg>
              <svg
                className={cn('h-6 w-6', {
                  block: isProfileDropdownOpen,
                  hidden: !isProfileDropdownOpen,
                })}
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  strokelnecap='round'
                  strokelnejoin='round'
                  strokeWidth='2'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div
        className={cn('md:hidden', {
          hidden: !isProfileDropdownOpen,
          block: isProfileDropdownOpen,
        })}
      >
        <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3'>
          <MainMenu routes={routes} isMobileLayout />
        </div>
        <div className='pt-4 pb-3 border-t border-gray-700'>
          <div className='flex items-center px-4'>
            <div className='flex-shrink-0'>
              <img
                className='h-10 w-10 rounded-full'
                src={userPictureUrl}
                alt=''
              />
            </div>
            <div className='ml-3'>
              <div className='text-base font-medium leading-none text-white'>
                {`${currentUserVar().firstname} ${currentUserVar().lastname}`}
              </div>
              <div className='text-sm font-medium leading-none text-gray-400'>
                {currentUserVar().userEmails.nodes[0].email}
              </div>
            </div>
            {/* <button className='ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
              <span className='sr-only'>View notifications</span>
              <svg
                className='h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  strokelnecap='round'
                  strokelnejoin='round'
                  strokeWidth='2'
                  d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
                />
              </svg>
            </button> */}
          </div>
          <div className='mt-3 px-2 space-y-1'>
            <span
              className='block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700'
              onClick={logout}
            >
              Sign out
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
}
