import React from 'react';
import UserProfile from '../components/UserProfile';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { DisplayErrorOrLoader } from '@components';
import { USER } from '@graphql/User';

function UserProfilePage() {
  const { id } = useParams();
  const { data, error, loading } = useQuery(USER, {
    variables: { id },
  });

  if (error || loading) {
    return <DisplayErrorOrLoader error={error} loading={loading} />;
  }

  return (
    <div className='flex justify-center'>
      <div className='w-1/4'>
        <UserProfile user={data.user} />
      </div>
    </div>
  );
}

export default UserProfilePage;
