import React from 'react';
import { Table, Td, Th, TBody, THead, Link } from '@components';

function LocationsTable({ locations }) {
  const locationRow = ({
    id,
    name,
    description,
    clapCount,
    user,
    locationType,
  }) => (
    <tr key={id}>
      <Td>
        <Link to={`/locations/${id}`}>{name}</Link>
      </Td>
      <Td className='w-5'>{description}</Td>
      <Td>{locationType}</Td>
      <Td>{clapCount}</Td>
      <Td>
        <Link to={`/users/${user.id}`}>{user.username}</Link>
      </Td>
    </tr>
  );
  return (
    <Table>
      <THead>
        <tr>
          <Th>Name</Th>
          <Th>Description</Th>
          <Th>Type</Th>
          <Th>Claps</Th>
          <Th>User</Th>
        </tr>
      </THead>
      <TBody>{locations.map(locationRow)}</TBody>
    </Table>
  );
}

export default LocationsTable;
