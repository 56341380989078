import React from 'react';
import { Table, Td, Th, TBody, THead } from '@components';
import Avatar from './UserAvatar';

export function UsersTable({ users }) {
  const userRow = ({
    id,
    username,
    firstname,
    lastname,
    userEmails,
    gender,
    url,
  }) => {
    const email = userEmails.nodes.length ? userEmails.nodes[0].email : 'n/a';
    return (
      <tr key={id}>
        <Td>
          <Avatar url={url} userId={id} />
        </Td>
        <Td>{username}</Td>
        <Td>{firstname}</Td>
        <Td>{lastname}</Td>
        <Td>{email}</Td>
        <Td>{gender}</Td>
      </tr>
    );
  };

  return (
    <Table>
      <THead>
        <tr>
          <Th>Picture</Th>
          <Th>Username</Th>
          <Th>Firstname</Th>
          <Th>Lastname</Th>
          <Th>Email</Th>
          <Th>Gender</Th>
        </tr>
      </THead>
      <TBody>{users.map(userRow)}</TBody>
    </Table>
  );
}
