import { gql } from '@apollo/client';

export const USER = gql`
  query User($id: UUID!) {
    user(id: $id) {
      id
      username
      firstname
      lastname
      userEmails {
        nodes {
          email
        }
      }
      gender
      onboarded
      language
      url
      createdAt
    }
  }
`;
