import React from 'react';
import PropTypes from 'prop-types';

export function Button({ className, children, onClick }) {
  return (
    <button
      className={
        className +
        ' p-2 rounded text-white bg-indigo-600 hover:bg-indigo-700 font-semibold'
      }
      onClick={onClick}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
