import React from 'react';
import { Marker } from 'react-map-gl';
import { getLocationTypeIcon } from '@utils/image.utils';

function LocationMarker({ longitude, latitude, locationType }) {
  const imgSrc = getLocationTypeIcon(locationType);
  return (
    <Marker longitude={longitude} latitude={latitude}>
      <img src={imgSrc} alt={`${locationType} icon`} className='w-7 h-7' />
    </Marker>
  );
}

export default LocationMarker;
