import { gql } from '@apollo/client';
import { AUTH_INFO__USER } from './AuthInfo_User';

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      ...AuthInfo_User
    }
  }
  ${AUTH_INFO__USER}
`;
