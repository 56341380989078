import React from 'react';
import {
  formatAge,
  formatGender,
  formatLanguage,
  formatDate,
} from '@utils/format.utils';
import UserAvatar from './UserAvatar';

function UserProfile({ user }) {
  const {
    username,
    url,
    firstname,
    lastname,
    birthdate,
    email,
    gender,
    language,
    createdAt,
  } = user;
  return (
    <div className='flex flex-col items-center'>
      <UserAvatar url={url} className='h-32' />
      <div>
        <h3>
          {firstname} {lastname}
        </h3>
        <h4>@{username}</h4>
        <ul className='list-unstyled mt-2 font-weight-light'>
          <li>{formatAge(birthdate)}</li>
          <li>{email}</li>
          <li>{formatGender(gender)}</li>
          <li>{formatLanguage(language)}</li>
          <li>Joined: {formatDate(createdAt)}</li>
        </ul>
      </div>
    </div>
  );
}

export default UserProfile;
