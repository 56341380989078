import React from 'react';
import { useQuery } from '@apollo/client';

import { DisplayErrorOrLoader, StatBox } from '@components';
import { LOCATIONS } from '@graphql/Locations';
import LocationsTable from '../components/LocationsTable';

function LocationsTablePage(props) {
  const { loading, error, data } = useQuery(LOCATIONS);

  if (error || loading) {
    return <DisplayErrorOrLoader error={error} loading={loading} />;
  }

  return (
    <>
      <div className='pb-5 grid gap-4 grid-cols-3'>
        <StatBox title='Nb locations' value={data.locations.nodes.length} />
      </div>
      <LocationsTable locations={data.locations.nodes} />
    </>
  );
}

export default LocationsTablePage;
