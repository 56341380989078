import { gql } from '@apollo/client';

import { LOCATION_INFORMATION__LOCATION } from './LocationInformation_Location';

export const LOCATIONS = gql`
  query Locations {
    locations {
      nodes {
        ...LocationInformation_Location
      }
    }
  }
  ${LOCATION_INFORMATION__LOCATION}
`;
