import { gql } from '@apollo/client';

export const USERS = gql`
  query Users {
    users {
      nodes {
        id
        username
        firstname
        lastname
        userEmails {
          nodes {
            email
          }
        }
        gender
        onboarded
        language
        url
      }
    }
  }
`;
