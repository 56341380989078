import React, { useCallback } from 'react';
import MapGL from 'react-map-gl';
import { useHistory, useLocation } from 'react-router-dom';

import LocationMarker from './LocationMarker';
import UserProfile from '../../user/components/UserProfile';
import { formatLocationType, formatDate } from '@utils/format.utils';
import { Link } from '@components/Link';
import { Button } from '@components/Button';

export function LocationDetail({ location }) {
  const { state } = useLocation();
  const history = useHistory();

  const redirectToMap = useCallback(() => {
    history.push({
      pathname: '/locations/map',
      state: {
        viewport: state.viewport,
      },
    });
  }, [history, state]);

  /**
   * If user comes from the Location Map page, state holds the viewport of the Location Map
   * that way when the uses clicks the back button, we will display the Location Map with the same viewport the user left it
   */
  const renderBackToMapButton = useCallback(() => {
    const { viewport } = state || {};
    return viewport ? (
      <Button className='absolute z-30 m-2' onClick={redirectToMap}>
        Back to Map
      </Button>
    ) : null;
  }, [state, redirectToMap]);

  const {
    name,
    user,
    locationType,
    createdAt,
    clapCount,
    description,
    longitude,
    latitude,
  } = location || {};
  return (
    <>
      {renderBackToMapButton()}
      <div className='w-100'>
        <MapGL
          width='100%'
          height='40vh'
          latitude={latitude}
          longitude={longitude}
          zoom={14}
        >
          <LocationMarker
            latitude={latitude}
            longitude={longitude}
            locationType={locationType}
          />
        </MapGL>
      </div>
      <h1 className='py-3 font-bold'>{name}</h1>
      <div className='flex justify-between'>
        <div className='flex-1'>
          <h3>Information</h3>
          <ul className='list-unstyled'>
            <li>Type: {formatLocationType(locationType)}</li>
            <li>Creation Date: {formatDate(createdAt)}</li>
            <li>Claps: {clapCount}</li>
            <li>
              Description: <p>{description || 'No description'}</p>
            </li>
          </ul>
        </div>
        <div className='flex-1'>
          <h3>Explorator</h3>
          <Link to={`/users/${user && user.id}`}>
            <UserProfile user={user} />
          </Link>
        </div>
      </div>
    </>
  );
}

export default LocationDetail;
