import React from 'react';

import DashboardLayout from './shared/layout/DashboardLayout';
import AuthLayout from './shared/layout/AuthLayout';
import { useAuth } from './shared/providers/AuthProvider';
import { Spinner } from '@components/Spinner';

function LoadingScreen() {
  return (
    <div className='flex justify-center items-center h-screen'>
      <Spinner />
    </div>
  );
}

function App() {
  const { isAuthenticated, isAuthenticating } = useAuth();

  if (isAuthenticating) {
    return <LoadingScreen />;
  }

  return isAuthenticated ? <DashboardLayout /> : <AuthLayout />;
}

export default App;
