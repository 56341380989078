import React from 'react';
import { LocationDetail } from '../components/LocationDetail';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { DisplayErrorOrLoader } from '@components';
import { LOCATION } from '../../shared/graphql/Location';

function LocationDetailPage(props) {
  const { id } = useParams();
  const { data, error, loading } = useQuery(LOCATION, {
    variables: { id },
  });

  if (error || loading) {
    return <DisplayErrorOrLoader loading={loading} error={error} />;
  }

  return <LocationDetail location={data.location} />;
}

export default LocationDetailPage;
