import { CompositeLayer } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import Supercluster from 'supercluster';
import { getClusterIcon, getLocationTypeIcon } from '@utils/image.utils';

function getIconObject(data) {
  const { properties } = data;

  let url;
  if (properties.cluster) {
    /**
     * properties when cluster definition:
     * {
     *   cluster: true,
     *   cluster_id: 931,
     *   point_count: 3,
     *   point_count_abbreviated: 3
     * }
     */
    url = getClusterIcon(properties.point_count);
  } else {
    /**
     * properties when it is not a cluster: LocationInformation_Location Graphql Fragment
     */
    url = getLocationTypeIcon(properties.locationType);
  }

  return {
    url,
    x: 0,
    y: 0,
    width: 200,
    height: 200,
  };
}

function getIconSize(size) {
  return Math.min(100, size) / 100 + 1;
}

export default class IconClusterLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex =
      changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      // Set maxZoom to a higher value to show location icons with less zoom
      const index = new Supercluster({ maxZoom: 14, radius: props.sizeScale });
      index.load(
        props.data.map((d) => ({
          geometry: {
            coordinates: props.getPosition(d),
          },
          properties: d, // ExploraDogs Location data
        }))
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z,
      });
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== 'hover') {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((f) => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const { data } = this.state;
    const { sizeScale, id = 'cluster-layer', pickable = false } = this.props;

    return new IconLayer(
      this.getSubLayerProps({
        id,
        data,
        pickable,
        sizeScale,
        getPosition: (d) => d.geometry.coordinates,
        getIcon: (d) => getIconObject(d),
        getSize: (d) =>
          getIconSize(d.properties.cluster ? d.properties.point_count : 1),
        onClick: (d) => alert(JSON.stringify(d)),
      })
    );
  }
}
