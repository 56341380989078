import React from 'react';
import { useQuery } from '@apollo/client';

import { DisplayErrorOrLoader } from '@components/DisplayErrorOrLoader';
import { StatBox } from '@components/StatBox';
import { UsersTable } from '../components/UsersTable';
import { Language } from '../../shared/enums/language.enum';
import { USERS } from '../../shared/graphql/Users';

function UsersTablePage(props) {
  const { data, loading, error } = useQuery(USERS);

  if (error || loading) {
    return <DisplayErrorOrLoader error={error} loading={loading} />;
  }

  const frenchUsersCount = data.users.nodes.filter(
    (u) => u.language === Language.FR
  ).length;
  const englishUsersCount = data.users.nodes.length - frenchUsersCount;

  return (
    <>
      <div className='pb-5 grid gap-4 grid-cols-3'>
        <StatBox title='Nb users' value={data.users.nodes.length} />

        <StatBox title='🇫🇷 Users' value={frenchUsersCount} />

        <StatBox title='🇬🇧 Users' value={englishUsersCount} />
      </div>
      <UsersTable users={data.users.nodes} />
    </>
  );
}

export default UsersTablePage;
