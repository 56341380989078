import { gql } from '@apollo/client';

import { LOCATION_INFORMATION__LOCATION } from './LocationInformation_Location';

export const LOCATION = gql`
  query Location($id: UUID!) {
    location(id: $id) {
      ...LocationInformation_Location
    }
  }
  ${LOCATION_INFORMATION__LOCATION}
`;
