import React from 'react';
import LocationMapPage from './location/pages/LocationMapPage';
import LocationsTablePage from './location/pages/LocationTablePage';
import UsersTablePage from './user/pages/UsersTablePage';
import UserProfilePage from './user/pages/UserProfilePage';
import LocationDetailPage from './location/pages/LocationDetailPage';

const routes = [
  {
    showInNavbar: true,
    path: '/locations/map',
    name: 'Map',
    fullscreen: true,
    component: () => <LocationMapPage />,
  },
  {
    showInNavbar: true,
    path: '/locations',
    name: 'Locations',
    component: () => <LocationsTablePage />,
  },
  {
    path: '/locations/:id',
    name: 'Location Details',
    component: () => <LocationDetailPage />,
  },
  {
    showInNavbar: true,
    path: '/users',
    name: 'Users',
    component: () => <UsersTablePage />,
  },
  {
    path: '/users/:id',
    name: 'User Details',
    component: () => <UserProfilePage />,
  },
  // {
  //   path: '*',
  //   component: () => <div>Wrong path, this route does not exist!</div>
  // }
];

export default routes;
