import React from 'react';
import PropTypes from 'prop-types';

export function StatBox({ title, value }) {
  return (
    <div className='p-4 bg-white rounded-xl shadow'>
      <h5 className='text-secondary pb-1'>{title}</h5>
      <span className='text-3xl font-extrabold'>{value}</span>
    </div>
  );
}

StatBox.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
