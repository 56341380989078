import routes from '../../routes';

const routeNotFound = {
  name: 'Route not found',
};

export function getRouteDefinition(activeRoutePath) {
  if (!activeRoutePath) return routeNotFound;

  const activeRouteSegments = activeRoutePath.split('/').filter(Boolean);

  for (let route of routes) {
    const routeSegments = route.path.split('/').filter(Boolean);
    if (routeSegments.length === activeRouteSegments.length) {
      const idxToCompare = routeSegments.reduce(
        (idxToCompare, element, idx) => {
          if (!element.startsWith(':')) {
            idxToCompare.push(idx);
          }

          return idxToCompare;
        },
        []
      );

      const isActiveRouteFound = idxToCompare.every(
        (idx) => activeRouteSegments[idx] === routeSegments[idx]
      );
      if (isActiveRouteFound) {
        return route;
      }
    }
  }

  return routeNotFound;
}
