import React from 'react';
import { ErrorMessage } from './ErrorMessage';
import { Loader } from './Loader';

export function DisplayErrorOrLoader({ error, loading }) {
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <Loader />;
  }

  return null;
}
