import { gql } from '@apollo/client';

import { AUTH_INFO__USER } from './AuthInfo_User';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { username: $email, password: $password }) {
      user {
        ...AuthInfo_User
      }
    }
  }

  ${AUTH_INFO__USER}
`;
