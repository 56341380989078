export const LocationType = {
  DOG_BAG: 'DOG_BAG',
  FOUNTAIN: 'FOUNTAIN',
  TRASH: 'TRASH',
  DOG_PARK: 'DOG_PARK',
  BATHING_SPOT: 'BATHING_SPOT',
  CAFE: 'CAFE',
  RESTAURANT: 'RESTAURANT',
  SHOP: 'SHOP',
  DOG_WALK: 'DOG_WALK'
};
