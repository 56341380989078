import React, { useState } from 'react';
import { client } from '../../config/apollo';
import { CURRENT_USER } from '@graphql/CurrentUser';
import { LOGIN } from '@graphql/Login';
import { LOGOUT } from '@graphql/Logout';
import { currentUserVar } from '@graphql/reactive-variables/current-user.var';

const AuthContext = React.createContext();

export function AuthProvider(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [
    isAuthenticationAttemptDone,
    setIsAuthenticationAttemptDone,
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Try to fetch current user with the cookie
  // If no cookie or expired cookie, the request fails
  // and the user will be redirected to the login page
  const silentLogin = async () => {
    setIsAuthenticationAttemptDone(true);
    setIsAuthenticating(true);

    try {
      const { data } = await client.query({ query: CURRENT_USER });

      if (data.currentUser) {
        currentUserVar(data.currentUser);
        setIsAuthenticated(true);
      }
    } catch (err) {
      console.log('Silent Login error: ', err);
    } finally {
      setIsAuthenticating(false);
    }
  };

  const login = async (email, password) => {
    setLoading(true);
    setError('');

    try {
      const { data } = await client.mutate({
        mutation: LOGIN,
        variables: { email, password },
      });

      if (data && data.login.user.isAdmin) {
        currentUserVar(data.login.user);
        setIsAuthenticated(true);
        localStorage.setItem('authenticated', true);
      } else {
        setError('Not an admin');
      }
    } catch (err) {
      const message =
        (err.graphQLErrors &&
          err.graphQLErrors.length &&
          err.graphQLErrors[0].message) ||
        'Login Error';
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    //TODO: Use useMutation instead
    const { data, error: logoutError } = await client.mutate({
      mutation: LOGOUT,
    });

    if (data) {
      setIsAuthenticated(false);
      localStorage.setItem('authenticated', false);
      setLoading(false);
      client.clearStore();
    }

    if (logoutError) {
      setError(error);
      setLoading(false);
    }
  }; //  and the user data

  if (!isAuthenticated && !isAuthenticating && !isAuthenticationAttemptDone) {
    silentLogin();
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticating,
        isAuthenticated,
        login,
        logout,
        loading,
        error,
      }}
      {...props}
    />
  );
}

export const useAuth = () => React.useContext(AuthContext);
