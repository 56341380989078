import React from 'react';

export function Th({ children }) {
  return (
    <th
      scope='col'
      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
    >
      {children}
    </th>
  );
}

export function Td({ children, className = '' }) {
  return (
    <td className={'px-6 py-4 whitespace-nowrap' + className}>{children}</td>
  );
}

export function TBody({ children }) {
  return (
    <tbody className='bg-white divide-y divide-gray-200'>{children}</tbody>
  );
}

export function THead({ children }) {
  return <thead className='bg-gray-50'>{children}</thead>;
}

export function Table({ children }) {
  return (
    <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
      <table className='min-w-full divide-y divide-gray-200'>{children}</table>
    </div>
  );
}
