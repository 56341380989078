import React from 'react';
import exploradogsLogo from '../../assets/img/exploradogs-logo.png';
import LoginFormContainer from '../containers/LoginFormContainer';

function LoginPage() {
  return (
    <div className='max-w-md w-full space-y-8'>
      <div>
        <img
          className='mx-auto h-24 w-auto rounded'
          src={exploradogsLogo}
          alt='Workflow'
        />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
          Sign in to your account
        </h2>
      </div>
      <LoginFormContainer />
    </div>
  );
}

export default LoginPage;
