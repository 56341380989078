import React from 'react';
import { Link } from 'react-router-dom';

import defaultAvatar from '@assets/img/default-avatar.png';
import { getThumbnailUrl } from '@utils/image.utils';

function UserAvatar({ url, userId, className = 'h-12' }) {
  console.log('\n\n\nurl: ', url);
  console.log('getThumbnailUrl(url): ', getThumbnailUrl(url));
  const img = (
    <img
      src={getThumbnailUrl(url) || defaultAvatar}
      className={`${className} rounded`}
      alt='user avatar'
    />
  );
  return userId ? <Link to={`/users/${userId}`}>{img}</Link> : img;
}

export default UserAvatar;
