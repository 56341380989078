import React from 'react';
import routes from '../../routes';
import { Navbar } from './Navbar';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { getRouteDefinition } from '../utils/routes.utils';
import 'mapbox-gl/dist/mapbox-gl.css';

function Main({ children, fullscreen }) {
  return (
    <main>
      {fullscreen ? (
        children
      ) : (
        <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>
          <div className='py-6'>{children}</div>
        </div>
      )}
    </main>
  );
}

function DashboardLayoutNew() {
  const { pathname } = useLocation();
  const { name: routeName, fullscreen = false } = getRouteDefinition(pathname);

  return (
    <>
      <div className='sticky top-0 z-50'>
        <Navbar routes={routes} />
      </div>
      <div className='z-10'>
        <header className='bg-white shadow'>
          <div className='max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8'>
            <h1 className='text-3xl font-bold leading-tight text-gray-900'>
              {routeName}
            </h1>
          </div>
        </header>
      </div>
      <div className='z-0'>
        <Main fullscreen={fullscreen}>
          <Switch>
            {routes.map((route) => (
              <Route key={route.path} {...route} exact />
            ))}
            <Redirect from='*' to='/locations/map' />
          </Switch>
        </Main>
      </div>
    </>
  );
}

export default DashboardLayoutNew;
