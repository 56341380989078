import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

export function Alert({ children, success, danger, warning, info }) {
  const className = cn('flex p-4 text-white pt-2 rounded font-bold text-2xl', {
    'bg-red-500': danger,
    'bg-yellow-500': warning,
    'bg-green-500': success,
    'bg-purple-500': info,
  });
  return <div className={className}>{children}</div>;
}

Alert.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string]),
  danger: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
};
