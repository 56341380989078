import { gql } from '@apollo/client';

export const AUTH_INFO__USER = gql`
  fragment AuthInfo_User on User {
    id
    language
    onboarded
    username
    firstname
    lastname
    birthdate
    gender
    url
    userEmails {
      nodes {
        email
      }
    }
    isAdmin
    createdAt
    updatedAt
  }
`;
