import { makeVar } from '@apollo/client';

const defultCurrentUser = {
  firstname: 'no-username',
  lastname: 'no-lastname',
  userEmails: {
    nodes: [
      {
        email: 'no-email',
      },
    ],
  },
};

export const currentUserVar = makeVar(defultCurrentUser);

