import React, { useState } from 'react';
import DeckGL from 'deck.gl';
import { _MapContext as MapContext, StaticMap } from 'react-map-gl';
import { useLocation } from 'react-router-dom';
import { LocationPopup } from './LocationPopup';

import IconClusterLayer from '@shared/map/layers/icon-cluster.layer';

const MAP_STYLE =
  'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json';

const DEFAULT_VIEWPORT = {
  latitude: 0,
  longitude: 0,
  zoom: 1,
  bearing: 0,
  pitch: 10,
};

// Montreal
// const INITIAL_VIEW_STATE = {
//   latitude: 45.5266645,
//   longitude: -73.561511,
//   zoom: 16,
//   bearing: 0,
//   pitch: 10,
// };

function LocationsMap(props) {
  const [selectedLocation, setSelectionLocation] = useState(null);
  const { state } = useLocation();

  /**
   * Check if the navigation state incudes a viewport state to use to initiate the map
   * This is used for the flow Map -> Location Detail -> Map where we want the user to go back
   * to the same viewport he was before he clicked to be redirected to a location detail page.
   */
  const initViewport = (state && state.viewport) || DEFAULT_VIEWPORT;
  const [viewport, setViewport] = useState(initViewport);

  const layers = [
    new IconClusterLayer({
      id: 'locations-layer',
      data: props.locations,
      pickable: true,
      sizeScale: 45,
      getPosition: (d) => d.coordinates,
      onClick: (data) => {
        setSelectionLocation(data.object);
      },
    }),
  ];

  return (
    <DeckGL
      initialViewState={viewport}
      controller={true}
      layers={layers}
      ContextProvider={MapContext.Provider}
      onViewStateChange={({ viewState }) => {
        const { latitude, longitude, zoom } = viewState;
        setViewport({ latitude, longitude, zoom });
      }}
    >
      <LocationPopup
        location={selectedLocation}
        onClose={() => setSelectionLocation(null)}
        getCurrentViewport={() => viewport}
      />
      <StaticMap mapStyle={MAP_STYLE} />
    </DeckGL>
  );
}

export default LocationsMap;
