import React from 'react';
import LoginForm from '../components/LoginForm';
import { useAuth } from '../../shared/providers/AuthProvider';

function LoginFormContainer(props) {
  const { login, error } = useAuth();
  return <LoginForm onSubmit={login} error={error} />;
}

export default LoginFormContainer;
