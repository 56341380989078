import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Popup } from 'react-map-gl';

export function LocationPopup({
  location = null,
  onClose,
  getCurrentViewport,
}) {
  const history = useHistory();

  /**
   * The redirect function add the current viewport as state to be able to go back to the same viewport
   * the user was before we was redirected to Location detail page
   */
  const redirectToLocationDetail = useCallback(() => {
    const { id } = location;
    const viewport = getCurrentViewport();
    history.push({
      pathname: `/locations/${id}`,
      state: {
        viewport,
      },
    });
  }, [history, getCurrentViewport, location]);

  return location ? (
    <Popup
      tipSize={12}
      offsetTop={18}
      anchor='top'
      longitude={location.longitude}
      latitude={location.latitude}
      closeOnClick={false}
      onClose={onClose}
      captureClick={true}
      sortByDepth={true}
    >
      <button onClick={redirectToLocationDetail}>
        <h1>{location.name}</h1>
      </button>
    </Popup>
  ) : null;
}
