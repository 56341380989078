import React from 'react';
import { useQuery } from '@apollo/client';

import { DisplayErrorOrLoader } from '@components/DisplayErrorOrLoader';
import LocationsMap from '../components/LocationsMap';
import { LOCATIONS } from '../../shared/graphql/Locations';

function LocationMapPage() {
  const { loading, error, data } = useQuery(LOCATIONS);
  if (error || loading) {
    return <DisplayErrorOrLoader error={error} loading={loading} />;
  }

  return <LocationsMap locations={data.locations.nodes} />;
}

export default LocationMapPage;
