import { LocationType } from '@enums/location-type.enum';
import bathingSpot from '@assets/icons/bathing-spot.icon.png';
import cafe from '@assets/icons/cafe.icon.png';
import dogPark from '@assets/icons/dog-park.icon.png';
import dogBag from '@assets/icons/dog-bag.icon.png';
import dogWalk from '@assets/icons/dog-walk.icon.png';
import fountain from '@assets/icons/fountain.icon.png';
import restaurant from '@assets/icons/restaurant.icon.png';
import shop from '@assets/icons/shop.icon.png';
import trash from '@assets/icons/trash.icon.png';
import cluster2 from '@assets/icons/cluster/cluster-2.icon.png';
import cluster3 from '@assets/icons/cluster/cluster-3.icon.png';
import cluster4 from '@assets/icons/cluster/cluster-4.icon.png';
import cluster5 from '@assets/icons/cluster/cluster-5.icon.png';
import cluster6 from '@assets/icons/cluster/cluster-6.icon.png';
import cluster7 from '@assets/icons/cluster/cluster-7.icon.png';
import cluster8 from '@assets/icons/cluster/cluster-8.icon.png';
import cluster9 from '@assets/icons/cluster/cluster-9.icon.png';
import cluster10 from '@assets/icons/cluster/cluster-10.icon.png';
import cluster20 from '@assets/icons/cluster/cluster-20.icon.png';
import cluster30 from '@assets/icons/cluster/cluster-30.icon.png';
import cluster40 from '@assets/icons/cluster/cluster-40.icon.png';
import cluster50 from '@assets/icons/cluster/cluster-50.icon.png';
import cluster60 from '@assets/icons/cluster/cluster-60.icon.png';
import cluster70 from '@assets/icons/cluster/cluster-70.icon.png';
import cluster80 from '@assets/icons/cluster/cluster-80.icon.png';
import cluster90 from '@assets/icons/cluster/cluster-90.icon.png';
import cluster100 from '@assets/icons/cluster/cluster-100.icon.png';
import defaultAvatar from '@assets/img/default-avatar.png';

export function getThumbnailUrl(url) {
  if (!url) {
    return defaultAvatar;
  }

  if (!url.includes('imagekit')) {
    // Only imagekit url can be formatted on the fly
    return url;
  }

  const cdnBaseUrl = process.env.REACT_APP_CDN_BASE_URL;

  if (url) {
    return `${cdnBaseUrl}/tr:w-200,h-200${url.split(cdnBaseUrl)[1]}`;
  }
}

export function getLocationTypeIcon(locationType) {
  switch (locationType) {
    case LocationType.BATHING_SPOT:
      return bathingSpot;
    case LocationType.CAFE:
      return cafe;
    case LocationType.DOG_PARK:
      return dogPark;
    case LocationType.DOG_BAG:
      return dogBag;
    case LocationType.DOG_WALK:
      return dogWalk;
    case LocationType.FOUNTAIN:
      return fountain;
    case LocationType.RESTAURANT:
      return restaurant;
    case LocationType.SHOP:
      return shop;
    case LocationType.TRASH:
      return trash;
    default:
      break;
  }
}

export function getClusterIcon(size) {
  let sizeStr;
  if (size < 10) {
    sizeStr = `${size}`;
  } else if (size < 100) {
    sizeStr = `${Math.floor(size / 10)}0`;
  }

  switch (sizeStr) {
    case '2':
      return cluster2;
    case '3':
      return cluster3;
    case '4':
      return cluster4;
    case '5':
      return cluster5;
    case '6':
      return cluster6;
    case '7':
      return cluster7;
    case '8':
      return cluster8;
    case '9':
      return cluster9;
    case '10':
      return cluster10;
    case '20':
      return cluster20;
    case '30':
      return cluster30;
    case '40':
      return cluster40;
    case '50':
      return cluster50;
    case '60':
      return cluster60;
    case '70':
      return cluster70;
    case '80':
      return cluster80;
    case '90':
      return cluster90;
    default:
      return cluster100;
  }
}
