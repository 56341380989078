import { gql } from '@apollo/client';

export const LOCATION_INFORMATION__LOCATION = gql`
  fragment LocationInformation_Location on Location {
    id
    name
    description
    clapCount
    userClapCount
    locationPictures {
      nodes {
        url
      }
    }
    bookmarked
    user {
      id
      username
      avatarUrl
      createdAt
    }
    locationType
    latitude
    longitude
    coordinates
    createdAt
  }
`;
