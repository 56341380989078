import moment from 'moment';
import { Gender } from '@enums/gender.enum';
import { Language } from '@enums/language.enum';

export function formatAge(birthdate) {
  if (birthdate) {
    return `${moment().diff(moment(birthdate), 'years')} years old`;
  }
}

export function formatDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function formatLanguage(language) {
  if (language === Language.FR) {
    return 'French';
  }

  return 'English';
}

export function formatGender(gender) {
  if (gender === Gender.FEMALE) {
    return 'Woman';
  } else if (gender === Gender.MALE) {
    return 'Man';
  }

  return 'Other';
}

export function formatLocationType(locationType) {
  return locationType.split('_').map(_capitalize).join(' ');
}

const _capitalize = (s) => {
  if (typeof s !== 'string') return '';
  s = s.toLowerCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
};
